<script setup lang="ts">
const realtime = useRealtimeStore()
const { user } = await useUser()

const token = user.value?.primary ? user.value.id : user.value?.partner?.id

const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws'

const url = `${wsProtocol}://${location.host}/api/websocket?token=${token}`

const { status, data, send, open, close } = useWebSocket(url)

watch(data, async newValue => {
  if (newValue !== '') {
    const [key, date] = newValue.split(':')

    await refreshNuxtData(key)
  }
})

watch(
  () => realtime.cacheKey,
  newKey => {
    if (newKey !== '') {
      send(`${newKey}:${Date.now()}`)

      realtime.cacheKey = ''
    }
  }
)
</script>

<template>
  <div></div>
</template>
